<template>
  <div id="container" class="hfeed wpbf-page">
    <!-- Header Nav Bar -->
    
    <div data-elementor-type="wp-page" data-elementor-id="2184" class="elementor elementor-2184">
      <div class="elementor-section-wrap">
        <!-- Background Slider -->
        
        <section
          class="elementor-section elementor-section-height-full elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
          data-particle_enable="false"
          data-particle-mobile-disabled="false"
          data-id="b3b6b9b"
        >
          <div class="elementor-background-overlay"></div>
          <div class="elementor-container elementor-column-gap-no">
            <div class="elementor-column elementor-col-100 elementor-top-column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <section
                  class="elementor-section elementor-inner-section elementor-element elementor-section-boxed"
                  data-id="b01d0b2"
                  data-element_type="section"
                >
                  <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-inner-column">
                      <div class="elementor-widget-wrap elementor-element-populated">
                        <div class="elementor-element elementor-widget elementor-widget-form">
                          <!-- START of Review Us Form -->
                          <div class="elementor-widget-container">
                            <div v-if="isLoading">loading......</div>
                            <div v-else>
                              <!-- Tab Navigation -->
                              <div class="tabs">
    <div
        v-for="(question, index) in questions"
        :key="question.id"
        class="tab-header"
        :class="{ active: currentTab === index }"
    >
        <div class="circle" :class="{ answered: answers[question.id].answer_text || answers[question.id].rate }">
            {{ index + 1 }}
        </div>
        <div v-if="index < questions.length - 1" class="line" :class="{ active: currentTab === index + 1 }"></div>
    </div>
    <div
        class="tab-header"
        :class="{ active: currentTab === questions.length }"
    >
        <div class="line" :class="{ active: currentTab === questions.length }"></div>
        <div class="circle">{{ questions.length + 1 }}</div> <!-- Comments -->
    </div>
</div>


                              <!-- Tab Content -->
                              <form @submit.prevent="submitSurvey">
                                <div v-show="currentTab !== null">
                                  <!-- Display Question if it's not the last tab -->
                                  <div v-if="currentTab < questions.length" class="quest">
                                    <p class="quest-text">{{ questions[currentTab].question_text }}? <span>*</span></p>

                               
  <!-- Use the RatingQuestion component -->
  <RatingQuestion 
        v-if="questions[currentTab].type === 'rating'"
        :question="questions[currentTab]"
        :key="questions[currentTab].id"
        :answers="answers"
        :ratingOptions="ratingOptions"
      />
                                    <!-- Text Question Types -->
                                    <div v-if="questions[currentTab].type === 'text'">
                                      <textarea
                                        v-model="answers[questions[currentTab].id].answer_text"
                                        class="textarea"
                                        placeholder="Type your response here..."
                                      ></textarea>
                                    </div>
                                  </div>

                                  <!-- Comments Section on the last tab -->
                                  <div v-if="currentTab === questions.length" class="quest">
                                    <p class="quest-text">Comments</p>
                                    <textarea
                                      v-model="comment_text"
                                      class="textarea"
                                      placeholder="Leave a comment..."
                                    ></textarea>
                                  </div>
                                </div>

                                <div class="navigation">
                                 <a href="javascript:void(0)" class="prev-btn" :class="{ 'last-question-style': currentTab == questions.length  }" @click="prevQuestion"  v-if="currentTab > 0" >Previous</a>
                                  <a
                                  href="javascript:void(0)" 
                                     class="next-btn"
                                     :class="{ 'first-question-style': currentTab === 0 }"
                                     @click="nextQuestion"
                                   v-if="currentTab < questions.length"
                                                  >
                                              Next
                                            </a>

                                  <button
                                   type="submit"
                                    class="submit-btn"
                                     v-if="currentTab === questions.length"
                                      :disabled="isSubmitting"
                                                        >
                                       {{ isSubmitting ? "Submitting..." : "Submit Survey" }} <!-- Change button text when submitting -->
                                          </button>

                                </div>
                              </form>
                            </div>
                          </div>
                          <!-- END of Survey Form -->
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import RatingQuestion from './RatingQuestion.vue'; 
export default {
  name: "RateUs",
    components: {
    RatingQuestion
  },
  data() {
    return {
    
      questions: null,
      
      answers: {},
      isLoading: true,
      lang: "",
      isSubmitting: false,
      currentTab: 0,
      comment_text: "",
      guestInfo: null,
      ratingOptions: [
        { value: 1, label: "Very Bad" },
        { value: 2, label: "Bad" },
        { value: 3, label: "Good" },
        { value: 4, label: "Very Good" },
        { value: 5, label: "Excellent" }
      ]
    };
  },
 
  created() {
    if(!User.loggedIn()){
      this.$router.push({name:'GuestLogin' });
        }
    this.getGuestInfo(); // Fetch guest info first
  },
  methods: {
    setLang() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "api/frontend-keywords?api_key=" +
            process.env.VUE_APP_API_KEY +
            "&lang=" +
            localStorage.getItem("localization")
        )
        .then((res) => {
          this.lang = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getGuestInfo() {
      this.axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
          "api/user-profile?api_key=" +
          process.env.VUE_APP_API_KEY +
          "&lang=" +
          process.env.VUE_APP_VUE_LANG +
          "&api_token=" +
          localStorage.getItem("token") +
          "&hotel_id=1&restaurant_id=1"
        )
        .then((res) => {
          this.guestInfo = res.data.data; // Store full guest info
          this.getQuestions(); // Now that guest info is available, fetch questions
        })
        .catch((error) => {
          console.error("Error fetching guest information:", error);
        });
    },
    getQuestions() {
  if (!this.guestInfo) {
    console.error("Guest is not logged in");
    return;
  }
  
  this.axios
    .get(
      `${process.env.VUE_APP_API_BASE_URL}api/survey/active/questions?api_key=${process.env.VUE_APP_API_KEY}&lang=${process.env.VUE_APP_LANG}&api_token=${localStorage.getItem("token")}`
    )
    .then((res) => {
      this.questions = res.data.data.questions;
      this.questions.forEach((question) => {
        this.answers[question.id] = {
          question_id: question.id,
          rate: null,
          answer_text: "",
        };
      });
      this.currentTab = 0; // Initialize with the first tab
      this.isLoading = false;
    })
    .catch((error) => console.log(error));
}
,
    nextQuestion() {
      if (this.currentTab < this.questions.length) {
        // Validate current answer before proceeding
        const currentQuestion = this.questions[this.currentTab];
        if (currentQuestion.type === 'radio' && !this.answers[currentQuestion.id].answer_text) {
          alert('Please select an answer for the current question.');
          return;
        }
        if (currentQuestion.type === 'rating' && this.answers[currentQuestion.id].rate === null) {
          alert('Please select a rating for the current question.');
          return;
        }
        this.currentTab++;
      }
    },
    prevQuestion() {
      if (this.currentTab > 0) {
        this.currentTab--;
      }
    },
    submitSurvey() {
  if (!this.guestInfo) {
    console.error("Guest is not logged in");
    return;
  }

  this.isSubmitting = true; // Disable the button when submission starts

  const formData = {
    api_key: process.env.VUE_APP_API_KEY,
    lang: localStorage.getItem('localization'),
    api_token: localStorage.getItem("token"),
    guests_id: this.guestInfo.id, // Use dynamically fetched guest info
    answers: Object.keys(this.answers).map((questionId) => ({
      question_id: questionId,
      rate: this.answers[questionId].rate,
      answer_text: this.answers[questionId].answer_text,
    })),
    comment_text: this.comment_text ||  null ,
  };

  this.axios
    .post(
      process.env.VUE_APP_API_BASE_URL + "api/survey-submit",
      formData,
      {
        headers: {
          Authorization: localStorage.getItem("token"), // Add the token here
        },
      }
    )
    .then((res) => {
      if (res.data.status === "ERROR") {
        this.$notify({
          group: "foo",
          title: "Survey Submission Error",
          text: res.data.message,
          type: "error",
        });
        this.isSubmitting = false; // Enable the button if submission fails
      } else {
        this.$notify({
          group: "foo",
          title: "Survey Submitted",
          text: "Survey submitted successfully!",
          type: "success",
        });

        setTimeout(() => {
          this.$router.push({ name: 'Home' }); // Redirect to home page
        }, 2000); // 2-second delay before redirecting
      }
    })
    .catch((error) => {
      console.error("Error submitting survey:", error);
      this.isSubmitting = false; // Enable the button if there is an error
    });
},

  }
};
</script>
<style scoped>
.elementor-widget-container {
  background-color: #e84f82;
  height: auto; /* Change to auto for better adaptability */
  width: 100%;
  max-width: 1400px; /* Set a max-width for larger screens */
  margin: 0 auto; /* Center align container */
  margin-bottom: 10%;
  justify-content: left;
  text-align:left;
  padding: 69px;
  font-size: large;
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.tab-header {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 35px; /* Set desired circle size */
  height: 35px; /* Set desired circle size */
  border-radius: 50%; /* Makes it circular */
  background-color: #dddddd; /* Default circle color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold; /* Optional for better visibility */
  margin: 5px;
}

.circle.answered {
  background-color: rgb(73, 214, 73); /* Change color for answered circles */
}

.line {
  width: 2px; /* Line thickness */
  height: 60px; /* Line height */
  background-color: #ddd; /* Default line color */
  margin: 0 25px; /* Space between circle and line */
  transform: rotate(90deg); /* Corrected rotation property */
}

.tab-header.active .circle {
  background-color: rgb(13, 0, 128); /* Change circle color when active */
  color: white;
}

.quest {
  justify-content: start;
  color: white;
  
  margin: 10px;
}

.quest-text {
  color: white;
  font-size: 30px;
}

.textarea {
  width: 100%; /* Make it full width */
  max-width: 500px; /* Set a maximum width */
  height: 56px;
  border: 1px solid #ccc;
  border-radius: 8px; /* More rounded corners */
  padding: 15px; /* More padding for comfort */
  font-size: 16px; /* Increased font size for better readability */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transitions */
  outline: none; /* Remove default outline */
  resize: none; /* Prevent resizing */
  text-wrap:wrap;
}

.textarea::placeholder {
  color: #aaa; /* Light color for placeholder text */
  font-style: italic; /* Italic style for placeholder */
}

/* Button Styles */
.navigation {
  display: flex; /* Use flexbox for navigation */
  justify-content: left; /* Center buttons horizontally */
  margin-top: 20px; /* Space above navigation */
}

.next-btn,
.submit-btn,
.prev-btn,
.first-question-style {
  text-decoration: none;
  background-color: #2528eb;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius:40px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px; /* Vertical margin */
  display: inline-block; /* To allow block-like behavior */
}

.submit-btn {
  background-color: #2528eb;
  margin-left: 10px;
}

.next-btn,
.prev-btn {
  width: auto; /* Reset width for responsiveness */
}
.next-btn{
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 120px;
}
.prev-btn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 120px;
}

.submit-btn:hover,
.next-btn:hover {
  background-color: #5761bb;
}

.prev-btn:hover {
  background-color: #cacaca;
}

input[type=radio] {
  margin-left: 15px;
}

.first-question-style {
  padding: 20px; /* Adjust padding for better appearance */
  width: 100%; /* Full width for responsiveness */
  max-width: 444px; /* Set a maximum width */
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-right: 50%;
}
.rating-options{
    font-size: 18px;
    
  }
  .quest-text {
    font-size: 40px;
    margin: 10px;
  }
  .last-question-style{
    display: none;
  }
/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .elementor-widget-container {
    padding: 20px;
   
  }
  
  .textarea {
    width: 90%; /* Full width for smaller screens */
  }

  .first-question-style,
  .next-btn,
  .prev-btn,
  .submit-btn {
    padding: 15px; /* Reduce padding */
    width: 90%; /* Full width for buttons */
  }

  .circle {
    width: 25px; /* Smaller circle size */
    height: 25px; /* Smaller circle size */
  }
  .quest-text{
    font-size: 30px;
  }

 
}

@media (max-width: 480px) {
  .circle {
    width: 20px; /* Even smaller circle size for very small screens */
    height: 20px; /* Even smaller circle size for very small screens */
  }

  .quest {
    margin: 5px; /* Reduce margin */
  }

 .quest-text {
    font-size: 24px;
    margin: 10px;
  }
  .rating-options{
    font-size: 14px;
    display: block;
  }
}
</style>
